<template>
  <div class="chat-container">
    <div class="chat-messages" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" :class="message.role">
        <div v-html="renderMarkdown(message.content)"></div>
      </div>
    </div>
    <div class="chat-input">
      <input v-model="userInput" @keyup.enter="sendMessage" placeholder="상품에 대해 물어보세요...">
      <button @click="sendMessage" :disabled="isLoading">전송</button>
    </div>
    <div class="voice-chat">
      <button v-if="!isVoiceChatting" @click="startVoiceChat" :disabled="isVoiceChatting">음성 채팅 시작</button>
      <button v-if="isVoiceChatting" @click="stopVoiceChat" :disabled="!isVoiceChatting">녹음 종료</button>
      <p v-if="recognizedText" style="display: none;">인식된 텍스트: {{ recognizedText }}</p>
      <audio ref="audioPlayer" controls style="display: none;"></audio>
      <audio ref="testAudioPlayer" controls style="display: none;"></audio>
      <button @click="playTestAudio" style="display: none;">테스트 오디오 재생</button>      
    </div>
    <div style="text-align: center;">
      <a href="/kiosk/1">RoboTalk Kiosk</a>
    </div>
  </div>
  
</template>

<script>
import { ref, onMounted, watch, nextTick } from 'vue';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { sendChatMessage, sendVoiceChatMessage } from '@/api/store';

export default {
  name: 'StoreChat',
  props: {
    storeId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const messages = ref([]);
    const userInput = ref('');
    const chatMessages = ref(null);
    const isLoading = ref(false);
    const audioPlayer = ref(null);
    const isVoiceChatting = ref(false);
    const recognizedText = ref('');

    let audioContext;
    let mediaStreamSource;
    let processor;
    const audioChunks = [];
    const testAudioPlayer = ref(null);
    let testAudioUrl = null;

    const renderMarkdown = (content) => {
      // marked를 사용하여 마크다운을 HTML로 변환
      const rawHtml = marked(content);
      // DOMPurify를 사용하여 HTML을 안전하게 정제
      return DOMPurify.sanitize(rawHtml);
    };

    const startVoiceChat = async () => {
      isVoiceChatting.value = true;
      recognizedText.value = '음성 인식 중...';
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            sampleRate: 16000,
            channelCount: 1,
            echoCancellation: true,
            noiseSuppression: true
          } 
        });
        audioContext = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 16000,
        });
        mediaStreamSource = audioContext.createMediaStreamSource(stream);
        processor = audioContext.createScriptProcessor(4096, 1, 1);

        mediaStreamSource.connect(processor);
        processor.connect(audioContext.destination);

        processor.onaudioprocess = (e) => {
          const inputData = e.inputBuffer.getChannelData(0);
          audioChunks.push(new Float32Array(inputData));
        };
      } catch (error) {
        console.error('Error accessing microphone:', error);
        recognizedText.value = '마이크 접근 오류';
        messages.value.push({ role: 'error', content: '마이크 접근에 실패했습니다.' });
      }
    };


    const stopVoiceChat = () => {
      if (processor && mediaStreamSource) {
        processor.disconnect();
        mediaStreamSource.disconnect();
        handleRecordingStop();
        isVoiceChatting.value = false;
      }
    };


    const handleRecordingStop = async () => {
      const sampleRate = 16000; // 16kHz로 고정
      const audioData = mergeAudioBuffers(audioChunks, sampleRate);
      const wavBlob = createWavBlob(audioData, sampleRate, 1);

      // 테스트용 오디오 URL 생성
      testAudioUrl = URL.createObjectURL(wavBlob);
      testAudioPlayer.value.src = testAudioUrl;

      const formData = new FormData();
      formData.append("audio_file", wavBlob, "audio.wav");
      try {
        const response = await sendVoiceChatMessage(props.storeId, formData, messages.value);
        if (response.error) {
          throw new Error(response.error);
        }
        recognizedText.value = response.recognizedText || '음성 인식 실패';
        if (response.data) {
          const responseAudioBlob = new Blob([response.data], { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(responseAudioBlob);
          audioPlayer.value.src = audioUrl;
          
          if (audioPlayer.value.paused) {
            audioPlayer.value.play();
          }

          messages.value.push({ role: 'user', content: recognizedText.value });
          messages.value.push({ role: 'assistant', content: response.responseText || '응답이 없습니다.' });
          scrollToBottom();
        }
      } catch (error) {
        console.error('Error in voice chat:', error);
        recognizedText.value = '음성 인식 중 오류 발생';
        // messages.value.push({ role: 'error', content: `음성 채팅 중 오류가 발생했습니다: ${error.message}` });
      }

      audioChunks.length = 0;
    };

    const playTestAudio = () => {
      if (testAudioUrl) {
        testAudioPlayer.value.play();
      } else {
        console.log("테스트할 오디오가 없습니다.");
      }
    };

    const mergeAudioBuffers = (buffers, sampleRate) => {
      let totalLength = buffers.reduce((acc, buf) => acc + buf.length, 0);
      let result = new Float32Array(totalLength);
      let offset = 0;
      for (let buffer of buffers) {
        result.set(buffer, offset);
        offset += buffer.length;
      }
      return result;
    };

    const createWavBlob = (audioData, sampleRate, numChannels) => {
      const buffer = new ArrayBuffer(44 + audioData.length * 2);
      const view = new DataView(buffer);

      writeString(view, 0, 'RIFF');
      view.setUint32(4, 36 + audioData.length * 2, true);
      writeString(view, 8, 'WAVE');
      writeString(view, 12, 'fmt ');
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true);
      view.setUint16(22, numChannels, true);
      view.setUint32(24, sampleRate, true);
      view.setUint32(28, sampleRate * numChannels * 2, true);
      view.setUint16(32, numChannels * 2, true);
      view.setUint16(34, 16, true);
      writeString(view, 36, 'data');
      view.setUint32(40, audioData.length * 2, true);

      const length = audioData.length;
      let index = 44;
      for (let i = 0; i < length; i++) {
        view.setInt16(index, Math.max(-1, Math.min(1, audioData[i])) * 0x7FFF, true);
        index += 2;
      }

      return new Blob([buffer], { type: 'audio/wav' });
    };

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    const logAudioData = (blob) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arrayBuffer = e.target.result;
        const dataView = new DataView(arrayBuffer);
        const firstBytes = [];
        for (let i = 0; i < 20 && i < arrayBuffer.byteLength; i++) {
          firstBytes.push(dataView.getUint8(i).toString(16).padStart(2, '0'));
        }
        console.log(`First 20 bytes of audio data: ${firstBytes.join(' ')}`);
      };
      reader.readAsArrayBuffer(blob.slice(0, 20));
    };


    const sendMessage = async () => {
      if (userInput.value.trim() === '' || isLoading.value) return;

      isLoading.value = true;
      const newMessage = { role: 'user', content: userInput.value };
      messages.value.push(newMessage);
      const botMessageIndex = messages.value.length;
      messages.value.push({ role: 'assistant', content: '' });

      try {
        const chatRequest = {
          message: userInput.value,
          conversation_history: messages.value.slice(0, -1)
        };
        console.log('Sending chat request:', chatRequest);
        const response = await sendChatMessage(props.storeId, chatRequest);
        
        const lines = response.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const content = line.slice(6).trim();
            if (content !== '[DONE]') {
              messages.value[botMessageIndex].content += content;
              await nextTick();
              scrollToBottom();
            }
          }
        }
      } catch (error) {
        console.error('Error sending message:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        messages.value[botMessageIndex].content = '죄송합니다. 오류가 발생했습니다. 다시 시도해 주세요.';
        messages.value[botMessageIndex].role = 'error';
      } finally {
        isLoading.value = false;
        userInput.value = '';
        scrollToBottom();
      }
    };

    const scrollToBottom = () => {
      if (chatMessages.value) {
        chatMessages.value.scrollTop = chatMessages.value.scrollHeight;
      }
    };

    watch(messages, scrollToBottom, { deep: true });

    onMounted(scrollToBottom);

    return {
      messages,
      renderMarkdown,
      userInput,
      sendMessage,
      chatMessages,
      isLoading,
      audioPlayer,
      isVoiceChatting,
      startVoiceChat,
      stopVoiceChat,
      recognizedText,
      testAudioPlayer,
      playTestAudio: () => {
        if (testAudioUrl) {
          testAudioPlayer.value.play();
        } else {
          console.log("테스트할 오디오가 없습니다.");
        }
      },
    };
  }
};
</script>

<style scoped>

.voice-chat {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  border: 1px solid #ccc;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

/* 마크다운 스타일 추가 */
.chat-messages :deep(pre) {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.chat-messages :deep(code) {
  font-family: 'Courier New', Courier, monospace;
}

.chat-messages :deep(p) {
  margin-bottom: 10px;
}

.chat-messages :deep(ul), .chat-messages :deep(ol) {
  padding-left: 20px;
}
.chat-input {
  display: flex;
  padding: 10px;
}

.chat-input input {
  flex: 1;
  margin-right: 10px;
}

.user, .assistant, .error {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 80%;
}

.user {
  background-color: #e6f3ff;
  align-self: flex-end;
  margin-left: auto;
}

.assistant {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.error {
  background-color: #ffe6e6;
  align-self: flex-start;
}
</style>